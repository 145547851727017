import '../css/index.scss'

;(function () {
  var btnDowns = document.getElementsByClassName('btn-download')
  var btnDowns64 = document.getElementsByClassName('btn-download64')
  var btnDownsx8 = document.getElementsByClassName('btn-downloadx8')
  var links = {
    ios: 'https://itunes.apple.com/cn/app/id1459678471?mt=8',
    android:
      'https://yydl.duowan.com/mobile/bilin-android/6.3.4/me-6.3.4-me_armeabi_v7a-guanwang.apk',
    android64:
      'https://yydl.duowan.com/mobile/bilin-android/6.3.4/me-6.3.4-me_arm64_v8a-guanwang.apk',
    androidx8:
      'https://yydl.duowan.com/mobile/bilin-android/6.3.4/me-6.3.4-me_x86-guanwang.apk',
    android_wechat:
      'https://sj.qq.com/myapp/detail.htm?apkName=com.yy.ourtimes',
    unknown: 'https://itunes.apple.com/cn/app/id1459678471?mt=8',
  }

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }

    if (/android/i.test(userAgent) && /micromessenger/i.test(userAgent)) {
      return 'Android_Wechat'
    }

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }

    return 'unknown'
  }

  function getDownloadLink() {
    window.jspcb201904191428 = function (resp) {
      
      if (resp.data) { 
        const res = {
          android64: resp.data['X64']?resp.data['X64'].downloadURL:links['android64'],
          androidx8:  resp.data['X86']?resp.data['X86'].downloadURL : links['androidx8'],
          ios:resp.data['IPHONE']?resp.data['IPHONE'].downloadURL  : links['ios'],
        }
        links  = res
      }
    }

    const isTest = window.location.href.includes('test')
    const script = document.createElement('script')
    script.src = `https://${isTest?'test-me':'me'}.mejiaoyou.com/common-models/h5/app-package/latest?callback=jspcb201904191428`
    script.type = 'text/javascript'
    document.body.appendChild(script)
    // const script1 = document.createElement('script')
    // script1.src = 'https://me.mejiaoyou.com/getConfigByKey.do?key=ANDROID_DOWNLOAD_URL_X86&callback=jspcb201904191428'
    // script1.type = 'text/javascript'
    // document.body.appendChild(script1)
  }

  getDownloadLink()

  for (var index = 0; index < btnDowns.length; index++) {
    var element = btnDowns[index]
    element.onclick = function (event) {
      event.preventDefault()
      var os = getMobileOperatingSystem().toLowerCase()
      if (os !== 'android_wechat') {
        window.location.href = links['ios']
      } else {
        document.querySelector('.shadow-container').style.display = 'block'
      }
    }
  }
  for (var index = 0; index < btnDowns64.length; index++) {
    var element = btnDowns64[index]
    element.onclick = function (event) {
      event.preventDefault()
      var os = getMobileOperatingSystem().toLowerCase()
      console.log(os,'os')
      if (os !== 'android_wechat') {
        window.location.href = links['android64']
      } else {
        document.querySelector('.shadow-container').style.display = 'block'
      }
    }
  }
  for (var index = 0; index < btnDownsx8.length; index++) {
    var element = btnDownsx8[index]
    element.onclick = function (event) {
      event.preventDefault()
      var os = getMobileOperatingSystem().toLowerCase()
      if (os !== 'android_wechat') {
        console.log(os,'os')
        window.location.href = links['androidx8']
      } else {
        document.querySelector('.shadow-container').style.display = 'block'
      }
    }
  }
})()
